import {
  callActionTypes,
  CallAction,
  CreateRoomRequestAction,
  CreateRoomSuccessAction,
  CreateRoomErrorAction,
  ConnectTimestampAction,
  ConnectTimestampExpiredAction,
  ConnectRequestAction,
  ConnectSuccessAction,
  ConnectErrorAction,
  EndAction,
  ParticipantConnectedAction,
  ParticipantDisconnectedAction,
  SetPermissionsAction,
  SetSupportedAction,
  CreateAction,
  EndRequestAction,
} from './Actions'
import { CallState } from './Types'

export const getInitialState = (): CallState => ({
  roomId: null,
  isSupported: null,
  permissions: {
    type: 'unknown',
    meta: null,
  },
  create: {
    isCreating: false,
    isCreated: false,
    requestCount: 0,
    error: null,
  },
  connect: {
    timestamp: null,
    timestampExpired: false,
    isConnecting: false,
    isConnected: false,
    requestCount: 0,
    error: null,
  },
  end: {
    isEnding: false,
  },
  participant: {
    connected: false,
    hasConnected: false,
  },
})

const create = (state: CallState, action: CreateAction): CallState => {
  // Create new room from initial state
  const newState = getInitialState()

  // ...and set some new values
  return {
    ...newState,
    roomId: action.payload.roomId,
  }
}

const createRoomRequest = (state: CallState, action: CreateRoomRequestAction): CallState => {
  return {
    ...state,
    create: {
      ...state.create,
      isCreating: true,
      requestCount: state.create.requestCount + 1,
    },
  }
}

const createRoomSuccess = (state: CallState, _action: CreateRoomSuccessAction): CallState => {
  return {
    ...state,
    create: {
      ...state.create,
      isCreating: false,
      isCreated: true,
      error: null,
    },
  }
}

const createRoomError = (state: CallState, action: CreateRoomErrorAction): CallState => {
  return {
    ...state,
    create: {
      ...state.create,
      isCreating: false,
      isCreated: false,
      error: action.payload.error,
    },
  }
}

const connectTimestamp = (state: CallState, action: ConnectTimestampAction): CallState => {
  return {
    ...state,
    connect: {
      ...state.connect,
      timestamp: action.payload.timestamp,
    },
  }
}

const connectTimestampExpired = (state: CallState, action: ConnectTimestampExpiredAction): CallState => {
  return {
    ...state,
    connect: {
      ...state.connect,
      timestampExpired: true,
    },
  }
}

const connectRequest = (state: CallState, action: ConnectRequestAction): CallState => {
  return {
    ...state,
    connect: {
      ...state.connect,
      isConnecting: true,
      requestCount: state.connect.requestCount + 1,
    },
  }
}

const connectSuccess = (state: CallState, action: ConnectSuccessAction): CallState => {
  return {
    ...state,
    connect: {
      ...state.connect,
      isConnecting: false,
      isConnected: true,
      error: null,
    },
  }
}

const connectError = (state: CallState, action: ConnectErrorAction): CallState => {
  return {
    ...state,
    connect: {
      ...state.connect,
      isConnecting: false,
      isConnected: false,
      error: action.payload.error,
    },
  }
}

const endRequest = (state: CallState, action: EndRequestAction): CallState => {
  return {
    ...state,
    end: {
      ...state.end,
      isEnding: true,
    },
  }
}

const end = (state: CallState, action: EndAction): CallState => {
  return {
    ...state,
    end: {
      ...state.end,
      isEnding: false,
    },
  }
}

const participantConnected = (state: CallState, _action: ParticipantConnectedAction): CallState => {
  return {
    ...state,
    participant: {
      ...state.participant,
      connected: true,
      hasConnected: true,
    },
  }
}

const participantDisconnected = (state: CallState, _action: ParticipantDisconnectedAction): CallState => {
  return {
    ...state,
    participant: {
      ...state.participant,
      connected: false,
    },
  }
}

const setSupported = (state: CallState, action: SetSupportedAction): CallState => {
  return {
    ...state,
    isSupported: action.payload.isSupported,
  }
}

const setPermissions = (state: CallState, action: SetPermissionsAction): CallState => {
  return {
    ...state,
    permissions: {
      type: action.payload.type,
      meta: action.payload.meta,
    },
  }
}

const reducer = (state: CallState = getInitialState(), action: CallAction): CallState => {
  let newState: CallState

  switch (action.type) {
    case callActionTypes.CREATE:
      newState = create(state, action as CreateAction)
      break
    case callActionTypes.CREATE_ROOM_REQUEST:
      newState = createRoomRequest(state, action as CreateRoomRequestAction)
      break
    case callActionTypes.CREATE_ROOM_SUCCESS:
      newState = createRoomSuccess(state, action as CreateRoomSuccessAction)
      break
    case callActionTypes.CREATE_ROOM_ERROR:
      newState = createRoomError(state, action as CreateRoomErrorAction)
      break
    case callActionTypes.CONNECT_TIMESTAMP:
      newState = connectTimestamp(state, action as ConnectTimestampAction)
      break
    case callActionTypes.CONNECT_TIMESTAMP_EXPIRED:
      newState = connectTimestampExpired(state, action as ConnectTimestampExpiredAction)
      break
    case callActionTypes.CONNECT_REQUEST:
      newState = connectRequest(state, action as ConnectRequestAction)
      break
    case callActionTypes.CONNECT_SUCCESS:
      newState = connectSuccess(state, action as ConnectSuccessAction)
      break
    case callActionTypes.CONNECT_ERROR:
      newState = connectError(state, action as ConnectErrorAction)
      break
    case callActionTypes.END_REQUEST:
      newState = endRequest(state, action as EndRequestAction)
      break
    case callActionTypes.END:
      newState = end(state, action as EndAction)
      break
    case callActionTypes.SET_SUPPORTED:
      newState = setSupported(state, action as SetSupportedAction)
      break
    case callActionTypes.PARTICIPANT_CONNECTED:
      newState = participantConnected(state, action as ParticipantConnectedAction)
      break
    case callActionTypes.PARTICIPANT_DISCONNECTED:
      newState = participantDisconnected(state, action as ParticipantDisconnectedAction)
      break
    case callActionTypes.SET_PERMISSIONS:
      newState = setPermissions(state, action as SetPermissionsAction)
      break
    default:
      newState = state
  }

  return newState
}

export default reducer
