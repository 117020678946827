import { ApolloClient } from 'apollo-client'

import links, { call, callNotes, callRating, callSummary } from '@api/links'
import { BlabuNextPageContext } from '@lib/types'
import { EndCallTrigger, RoomStateCheckQuery, RoomStateCheckQueryVariables } from '@gql/__generated__'
import redirect from '@helpers/redirect'
import getLogger from '@src/Logger'
import { isRoomFinished } from '@pages/call/Utils'

import ROOM_STATE from './operations/RoomStateQuery.api'

export enum Screen {
  Call,
  Rating,
  Notes,
  Summary,
  OutOfTheCall,
}

// skip rating in these cases
const shouldSkipRating = (trigger: EndCallTrigger) => ~[EndCallTrigger.Unload, EndCallTrigger.Aborted].indexOf(trigger)

export const getRoomState = (roomId: string, client: ApolloClient<any>) =>
  client.query<RoomStateCheckQuery, RoomStateCheckQueryVariables>({
    query: ROOM_STATE,
    variables: {
      roomId,
    },
  })

// tslint:disable-next-line:max-func-args
export const getNextScreen = async (
  roomId: string,
  client: ApolloClient<any>,
  ctx?: BlabuNextPageContext,
  currentScreen?: Screen,
): Promise<Screen> => {
  if (!roomId) {
    return Screen.OutOfTheCall
  }

  let showCall = false
  let showRating = false
  let showNote = false
  let showSummary = false

  try {
    const { data } = await getRoomState(roomId, client)
    showCall = data.room && !isRoomFinished(data.room)
    showRating = data.room && isRoomFinished(data.room) && !data.room.rating && !shouldSkipRating(data.room.trigger)
    showNote = data.room && isRoomFinished(data.room) && !!data.note?.note
    showSummary = data.room && isRoomFinished(data.room)
  } catch (err) {
    getLogger().error({ err }, 'Fetching room state failed')
  }

  if (showCall && (!currentScreen || Screen.Call > currentScreen)) {
    return Screen.Call
  }

  if (showRating && (!currentScreen || Screen.Rating > currentScreen)) {
    return Screen.Rating
  }

  if (showNote && (!currentScreen || Screen.Notes > currentScreen)) {
    return Screen.Notes
  }

  if (showSummary && (!currentScreen || Screen.Summary > currentScreen)) {
    return Screen.Summary
  }

  return Screen.OutOfTheCall
}

export const redirectToScreen = (roomId: string, screen: Screen, ctx?: BlabuNextPageContext) => {
  let target

  switch (screen) {
    case Screen.Call:
      target = call(roomId)
      break
    case Screen.Rating:
      target = callRating(roomId)
      break
    case Screen.Notes:
      target = callNotes(roomId)
      break
    case Screen.Summary:
      target = callSummary(roomId)
      break
    case Screen.OutOfTheCall:
      target = links.home
      break
    default:
      throw new Error('Unhandled screen')
  }

  redirect(ctx, target)
}

// tslint:disable-next-line:max-func-args
export const redirectToNextScreen = async (
  roomId: string,
  client: ApolloClient<any>,
  ctx?: BlabuNextPageContext,
  currentScreen?: Screen,
) => {
  let screen

  try {
    screen = await getNextScreen(roomId, client, ctx, currentScreen)
  } catch (err) {
    getLogger().error({ err }, 'getNextScreen failed')
  }

  redirectToScreen(roomId, screen, ctx)
}
