import gql from 'graphql-tag'

import RoomState from './RoomStateFragment.api'

export default gql`
  query RoomStateCheck($roomId: String!) {
    room: getRoom(id: $roomId) {
      id
      ...RoomState
    }
    note: getNote(roomId: $roomId) {
      id
      note
    }
  }

  ${RoomState}
`
