import { CallState } from './Types'
import { AppState } from '../../Types'

// @TODO Change calling to call when done
export const getSelf = (storeState: AppState): CallState => storeState.calling

export const getConnectTimestamp = (store: CallState): number | null => store.connect.timestamp

export const getParticipant = (store: CallState) => store.participant

export const isEnding = (store: CallState): boolean => store.end.isEnding

export const isConnected = (store: CallState): boolean => store.connect.isConnected

export const isConnectTimestampExpired = (store: CallState): boolean => store.connect.timestampExpired

export const getRoomId = (store: CallState): CallState['roomId'] => store.roomId

export const isPermissionsGranted = (store: CallState): boolean => store.permissions.type === 'granted'

export const getPermissions = (store: CallState): CallState['permissions'] => store.permissions

export const isSupported = (store: CallState): boolean | null => store.isSupported

export const shouldCreateRoom = (store: CallState): boolean => store.isSupported && store.create.requestCount <= 0

export const shouldConnect = (store: CallState): boolean =>
  store.isSupported && store.connect.timestampExpired && store.connect.requestCount <= 0
