import gql from 'graphql-tag'

export default gql`
  fragment RoomState on Room {
    id
    cancelled
    duration
    # Not present in onRoomEnded subscription
    # callInviteStatus
    end
    ended
    rating {
      id
    }
    reason
    resolution {
      width
      height
    }
    started
    trigger
  }
`
