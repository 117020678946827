import { CallState } from './Types'
import { EndCallTrigger } from '@gql/__generated__'

/* ACTION CREATOR FUNCTIONS
Put here the functions that return an action object that can be dispatched
HINT: Always use functions for consistency, don't export plain objects
*/

export const callActionTypes = {
  CREATE: 'call/create',
  CREATE_ROOM_REQUEST: 'call/createCallRequest',
  CREATE_ROOM_SUCCESS: 'call/createCallSuccess',
  CREATE_ROOM_ERROR: 'call/createCallError',
  CONNECT_TIMESTAMP: 'call/connectTimestamp',
  CONNECT_TIMESTAMP_EXPIRED: 'call/connectTimestampExpired',
  CONNECT_REQUEST: 'call/connectRequest',
  CONNECT_SUCCESS: 'call/connectSuccess',
  CONNECT_ERROR: 'call/connectError',
  END_REQUEST: 'call/endRequest',
  END: 'call/end',
  PARTICIPANT_DISCONNECTED: 'call/participantDisconnected',
  PARTICIPANT_CONNECTED: 'call/participantConnected',
  SET_PERMISSIONS: 'call/setPermissions',
  SET_SUPPORTED: 'call/setSupported',
}

export interface CreateAction {
  type: typeof callActionTypes.CREATE
  payload: {
    roomId: string
  }
}

export interface CreateRoomRequestAction {
  type: typeof callActionTypes.CREATE_ROOM_REQUEST
}

export interface CreateRoomSuccessAction {
  type: typeof callActionTypes.CREATE_ROOM_SUCCESS
}

export interface CreateRoomErrorAction {
  type: typeof callActionTypes.CREATE_ROOM_ERROR
  payload: {
    error: string
  }
}

export interface ConnectRequestAction {
  type: typeof callActionTypes.CONNECT_REQUEST
}

export interface ConnectSuccessAction {
  type: typeof callActionTypes.CONNECT_SUCCESS
}

export interface ConnectErrorAction {
  type: typeof callActionTypes.CONNECT_ERROR
  payload: {
    error: string
  }
}

export interface ConnectTimestampAction {
  type: typeof callActionTypes.CONNECT_TIMESTAMP
  payload: {
    timestamp: number
  }
}

export interface ConnectTimestampExpiredAction {
  type: typeof callActionTypes.CONNECT_TIMESTAMP_EXPIRED
}

export interface EndRequestAction {
  type: typeof callActionTypes.END_REQUEST
}

export interface EndAction {
  type: typeof callActionTypes.END
  payload: {
    trigger: EndCallTrigger
  }
}

export interface ParticipantConnectedAction {
  type: typeof callActionTypes.PARTICIPANT_CONNECTED
}

export interface ParticipantDisconnectedAction {
  type: typeof callActionTypes.PARTICIPANT_DISCONNECTED
}

export interface SetPermissionsAction {
  type: typeof callActionTypes.SET_PERMISSIONS
  payload: {
    type: CallState['permissions']['type']
    meta?: { localTracksOptions: Object; name: string; message: string; error: string } | null
  }
}

export interface SetSupportedAction {
  type: typeof callActionTypes.SET_SUPPORTED
  payload: {
    isSupported: boolean
  }
}

export const create = (roomId: string): CreateAction => ({
  type: callActionTypes.CREATE,
  payload: {
    roomId,
  },
})

export const createRoomRequest = (): CreateRoomRequestAction => ({
  type: callActionTypes.CREATE_ROOM_REQUEST,
})

export const createRoomSuccess = (): CreateRoomSuccessAction => ({
  type: callActionTypes.CREATE_ROOM_SUCCESS,
})

export const createRoomError = (error: string): CreateRoomErrorAction => ({
  type: callActionTypes.CREATE_ROOM_ERROR,
  payload: {
    error,
  },
})

export const connectTimestamp = (timestamp: number): CallAction => ({
  type: callActionTypes.CONNECT_TIMESTAMP,
  payload: {
    timestamp,
  },
})

export const connectTimestampExpired = (): CallAction => ({
  type: callActionTypes.CONNECT_TIMESTAMP_EXPIRED,
})

export const connectRequest = (): CallAction => ({
  type: callActionTypes.CONNECT_REQUEST,
})

export const connectSuccess = (): CallAction => ({
  type: callActionTypes.CONNECT_SUCCESS,
})

export const connectError = (error: string): CallAction => ({
  type: callActionTypes.CONNECT_ERROR,
  payload: {
    error,
  },
})

export const participantDisconnected = (): CallAction => ({
  type: callActionTypes.PARTICIPANT_DISCONNECTED,
})

export const endRequest = (): CallAction => ({
  type: callActionTypes.END_REQUEST,
})

export const end = (trigger: EndCallTrigger): CallAction => ({
  type: callActionTypes.END,
  payload: {
    trigger,
  },
})

export const participantConnected = (): ParticipantConnectedAction => ({
  type: callActionTypes.PARTICIPANT_CONNECTED,
})

export const setPermissions = (
  type: SetPermissionsAction['payload']['type'],
  meta: SetPermissionsAction['payload']['meta'] = null,
): SetPermissionsAction => ({
  type: callActionTypes.SET_PERMISSIONS,
  payload: {
    type,
    meta,
  },
})

export const setSupported = (isSupported: boolean): CallAction => ({
  type: callActionTypes.SET_SUPPORTED,
  payload: {
    isSupported,
  },
})

export type CallAction =
  | CreateAction
  | CreateRoomRequestAction
  | CreateRoomSuccessAction
  | CreateRoomErrorAction
  | ConnectRequestAction
  | ConnectSuccessAction
  | ConnectErrorAction
  | ConnectTimestampAction
  | ConnectTimestampExpiredAction
  | ParticipantDisconnectedAction
  | EndRequestAction
  | EndAction
  | ParticipantConnectedAction
  | SetPermissionsAction
  | SetSupportedAction
