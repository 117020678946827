import gql from 'graphql-tag'

import RoomState from './RoomStateFragment.api'

export default gql`
  mutation callEnded($roomId: String!, $trigger: EndCallTrigger!, $reason: String, $meta: CallEndedInfo) {
    room: callEndedv2(roomId: $roomId, trigger: $trigger, reason: $reason, meta: $meta) {
      ...RoomState
    }
  }

  ${RoomState}
`
