import gql from 'graphql-tag'

import RoomState from './RoomStateFragment.api'

export default gql`
  mutation StartCall($startCall: StartCallInput!) {
    startCall(startCall: $startCall) {
      room {
        end
        ...RoomState
      }
      status
    }
  }

  ${RoomState}
`
