import gql from 'graphql-tag'

import RoomState from './RoomStateFragment.api'

export default gql`
  mutation connectToRoom($id: String!) {
    connectedRoom: connectToRoom(id: $id) {
      participant {
        id
        profile: profileFixed {
          ... on Student {
            id
          }
          ... on Tutor {
            id
          }
        }
        token
      }
      reconnect
      room {
        id
        lessonId
        ...RoomState
      }
    }
  }

  ${RoomState}
`
