import { TwilioError } from 'twilio-video'

import { EndCallTrigger } from '@gql/__generated__'
import { Role } from '@store/modules/user/Types'
import { ArrayElementType } from '@lib/typeUtils'

type RoomWithParticipantIds = {
  participants?: {
    profile?: {
      id?: string
    }
  }[]
}

type RoomWithParticipantRoles = {
  participants: {
    profile: {
      role: string
    }
  }[]
}

type RoomWithTrigger = {
  trigger: EndCallTrigger
}

type RoomWithCancelledAndEnded = {
  cancelled?: string | null
  ended?: string | null
}

/**
 * Filters out Room participants with given profileIds and returns participant which is current user.
 */
export const getLocalParticipant = <Room extends RoomWithParticipantIds>(
  room: Room,
  currentUserProfileIds: string[],
): ArrayElementType<Room['participants']> => {
  // Participants with current user profile ID
  const localParticipants = room.participants.filter(
    participant => ~currentUserProfileIds.indexOf(participant.profile.id),
  )

  if (localParticipants.length !== 1) {
    throw new Error(
      'Invalid number of Room participants. We assume that 1 participant is current user and 1 participant is counterpart user.',
    )
  }

  return localParticipants[0] as any
}

/**
 * Filters out Room participants with given profileIds and returns participant which is not current user.
 */
export const getRemoteParticipant = <Room extends RoomWithParticipantIds>(
  room: Room,
  currentUserProfileIds: string[],
): ArrayElementType<Room['participants']> => {
  // Participants with different ID from current user profile IDs
  const remoteParticipants = room.participants.filter(
    participant => !~currentUserProfileIds.indexOf(participant.profile.id),
  )

  if (remoteParticipants.length !== 1) {
    throw new Error(
      'Invalid number of Room participants. We assume that 1 participant is current user and 1 participant is counterpart user.',
    )
  }

  return remoteParticipants[0] as any
}

export const getTutorParticipant = <Room extends RoomWithParticipantRoles>(
  room: Room,
): ArrayElementType<Room['participants']> => {
  const tutors = room.participants.filter(participant => participant.profile.role === Role.Tutor)
  const tutor = tutors[0] || null

  if (!tutor) {
    throw new Error('Missing tutor in Room participants. We assume that 1 participant is tutor.')
  }

  return tutor as any
}

// const TWILIO_ERROR_CODES = {
//   53106: 'Room not found',
//   53118: 'Room completed',
//   53000: 'Signaling connection error',
//   53404: 'No supported codec',
//   53405: 'Media connection failed or Media activity ceased',
// }

export const isConnectionProblem = (error: TwilioError) => {
  return !!~[53000, 53405].indexOf(error.code)
}

export const isRoomCancelled = <Room extends RoomWithTrigger>(room: Room): boolean =>
  room.trigger &&
  !~[EndCallTrigger.Button, EndCallTrigger.ButtonEarly, EndCallTrigger.ButtonFail, EndCallTrigger.Timeout].indexOf(
    room.trigger,
  )

/**
 * Checks if room has been cancelled (ended before it has started) or ended
 */
export const isRoomFinished = <Room extends RoomWithCancelledAndEnded>(room: Room): boolean =>
  Boolean(room.cancelled || room.ended)
