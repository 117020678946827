/* OPERATIONS = REDUX THUNKS / SAGAS...
This file defines the public interface of the duck -- what can be dispatched from components
Simple operations are just about forwarding an action creator
Complex operations involve returning a thunk that dispatches multiple actions in a certain order
*/

import { ApolloClient } from 'apollo-client'
import getConfig from 'next/config'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import Video, { ConnectOptions, Room as TwilioRoom, TwilioError } from 'twilio-video'

import { getExpirationTimestamp, waitUntil } from '@helpers/dateTime'
import { apiErrorToCode, apiErrorToString } from '@helpers/api'
import * as callSelectors from '@src/store/modules/calling/Selectors'
import { CancelReason } from '@src/store/modules/calling/Types'
import {
  CallEndedMutation,
  CallEndedMutationVariables,
  ConnectToRoomMutation,
  ConnectToRoomMutationVariables,
  EndCallTrigger,
  ProlongCallMutation,
  ProlongCallMutationVariables,
  StartCallMutation,
  StartCallMutationVariables,
  RoomStateFragment,
} from '@gql/__generated__'
import analytics from '@src/Analytics'
import getLogger from '@src/Logger'
import { differenceInSeconds } from 'date-fns'
import { seconds } from '@helpers/interval'
import { now } from '@lib/withTimeSync'
import { isRoomCancelled, isRoomFinished } from '@pages/call/Utils'

import PROLONG_MUTATION from './operations/ProlongMutation.api'
import CALL_ENDED_MUTATION from './operations/callEndedMutation.api'
import CONNECT_TO_ROOM from './operations/connectToRoomMutation.api'
import START_CALL from './operations/startCallMutation.api'
import * as actions from './Actions'
import { getRoomState, redirectToNextScreen } from './Utils'
import { AppState } from '../../Types'

let clearConnectTimer: () => void
let clearEndTimer: () => void

const { CALL_DURATION_EXTRA_INTERVAL, CALL_COUNTDOWN } = getConfig().publicRuntimeConfig

export const PROLONG_INTERVAL_MINUTES = 15

export const MAX_DURATION_MINUTES = 45

type CancelInput = {
  reason: CancelReason
  trigger: EndCallTrigger
  client: ApolloClient<any>
  roomId?: string
  meta?: {
    hasNetworkProblem: boolean
  }
}

export const cancel = (cancelInput: CancelInput): ThunkAction<Promise<void>, AppState, null, Action<string>> => async (
  dispatch,
  getState,
) => {
  const { reason, trigger, client, roomId: cancelRoomId, meta } = cancelInput
  const call = callSelectors.getSelf(getState())
  const roomId = callSelectors.getRoomId(call)

  if (cancelRoomId && roomId !== cancelRoomId) {
    return
  }

  clearConnectTimer && clearConnectTimer()
  clearEndTimer && clearEndTimer()

  const metadata = { ...call.permissions.meta, userAgent: navigator && navigator.userAgent, ...meta }

  try {
    const {
      data: { room },
    } = await getRoomState(roomId, client)
    // Prevent calling cancel multiple times
    if (isRoomFinished(room)) {
      getLogger().info(
        { obj: { roomId, reason } },
        `Cannot cancel room (with trigger ${trigger} and reason ${reason}). Room was already ${isRoomCancelled(room) ? 'canceled' : 'ended'
        } (with trigger ${room.trigger} and reason ${room.reason}).`,
      )
      return
    }
  } catch (err) {
    getLogger().error({ err }, 'Getting room state failed')
  }

  analytics.pushEvent('call/cancelled', {
    reason,
    roomId,
  })

  dispatch(actions.endRequest())
  getLogger().info({ obj: { roomId, reason, metadata } }, 'cancel (CallEndedMutation) mutation sent')

  let updatedRoom = null
  try {
    const { data } = await client.mutate<CallEndedMutation, CallEndedMutationVariables>({
      mutation: CALL_ENDED_MUTATION,
      variables: {
        reason,
        roomId,
        trigger,
        meta: metadata
          ? {
            values: metadata,
          }
          : null,
      },
    })
    updatedRoom = data.room
    getLogger().info({ obj: { roomId, reason, metadata } }, 'cancel (CallEndedMutation) mutation successful')
  } catch (err) {
    getLogger().error({ err }, 'cancel request failed')
  }
  // Optimistically end the call (disconnect from Twilio room).
  // We cannot let user stuck with active Twilio connection.
  dispatch(actions.end(trigger))

  if (!updatedRoom?.started || (updatedRoom && isRoomFinished(updatedRoom))) {
    // Intentionally not awaited
    redirectToNextScreen(roomId, client).catch(err => {
      getLogger().error({ err }, 'Redirect to next call screen failed')
    })
  }
}

export const create = actions.create

export const createRoom = (
  roomId: string,
  client: ApolloClient<any>,
): ThunkAction<Promise<any>, AppState, null, Action<string>> => async dispatch => {
  dispatch(actions.createRoomRequest())

  try {
    const result = await client.mutate<ConnectToRoomMutation, ConnectToRoomMutationVariables>({
      mutation: CONNECT_TO_ROOM,
      variables: { id: roomId },
    })
    dispatch(actions.createRoomSuccess())
    getLogger().info('connectWithDelay() executed after connectToRoom()')
    dispatch(connectWithDelay(CALL_COUNTDOWN))

    return result
  } catch (error) {
    getLogger().fatal({ err: error }, 'connectToRoom() mutation failed')
    dispatch(actions.createRoomError(apiErrorToString(error)))
    dispatch(
      cancel({
        reason: 'CANCELED_BEFORE_START' as CancelReason,
        trigger: EndCallTrigger.Unload,
        client,
      }),
    ).catch(err => getLogger().fatal({ err }, 'Call cancel failed'))
  }
}

export const connectWithDelay = (delay: number): ThunkAction<void, AppState, null, Action<string>> => dispatch => {
  const connectTimestamp = getExpirationTimestamp(delay)
  dispatch(actions.connectTimestamp(connectTimestamp))

  clearConnectTimer && clearConnectTimer()
  clearEndTimer && clearEndTimer()

  clearConnectTimer = waitUntil(connectTimestamp, () => {
    dispatch(actions.connectTimestampExpired())
  })
}

export const onEnded = (
  room: RoomStateFragment,
  client: ApolloClient<any>,
): ThunkAction<void, AppState, null, Action<string>> => (dispatch, getState) => {
  const roomId = callSelectors.getRoomId(callSelectors.getSelf(getState()))

  clearConnectTimer && clearConnectTimer()
  clearEndTimer && clearEndTimer()

  getLogger().info({ obj: { room } }, 'On room ended received')

  redirectToNextScreen(roomId, client).catch(err => {
    getLogger().error({ err }, 'Redirect to next call screen failed')
  })
}

export const onVideoConnected = (
  twilioSID: string,
  client: ApolloClient<any>,
): ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
  const roomId = callSelectors.getRoomId(callSelectors.getSelf(getState()))

  try {
    const { data } = await getRoomState(roomId, client)
    let room = data.room

    // Start room if it hasn't been started
    if (!room.started) {
      const variables = {
        startCall: {
          roomId,
          twilioSID,
        },
      }

      getLogger().info({ obj: variables }, 'Start call sent')
      const {
        data: { startCall },
      } = await client.mutate<StartCallMutation, StartCallMutationVariables>({
        mutation: START_CALL,
        variables,
      })
      room = startCall.room
      getLogger().info({ obj: variables }, 'Start call succeed')
    } else {
      getLogger().info('Call has already started')
    }

    // Room started, setup room end
    clearEndTimer && clearEndTimer()
    clearEndTimer = waitUntil(new Date(room.end).getTime() + seconds(CALL_DURATION_EXTRA_INTERVAL), () => {
      dispatch(end(EndCallTrigger.Timeout, client)).catch(err => getLogger().fatal({ err }, 'Call end failed'))
    })
  } catch (err) {
    getLogger().error({ err }, 'Start call failed')
    const input = {
      reason: apiErrorToCode(err) as CancelReason,
      trigger: EndCallTrigger.Unload,
      client,
    }
    dispatch(cancel(input)).catch(err => getLogger().fatal({ err }, 'Call cancel failed'))
  }
}

export const connect = (
  token: string,
  duration: number,
  connectOptions: ConnectOptions,
  client: ApolloClient<any>,
): ThunkAction<Promise<TwilioRoom | null>, AppState, null, Action<string>> => dispatch => {
  dispatch(actions.connectRequest())

  return Video.connect(token, connectOptions)
    .then((twilioRoom: TwilioRoom) => {
      dispatch(actions.connectSuccess())
      if (twilioRoom.participants.size > 0) {
        dispatch(actions.participantConnected())
      }

      return twilioRoom
    })
    .catch((error: TwilioError) => {
      const reason = 'TWILIO_ERROR'
      getLogger().fatal({ obj: { connectOptions }, err: error }, 'Video connect failed')
      dispatch(actions.connectError(error.message))
      dispatch(
        cancel({
          reason: reason as CancelReason,
          trigger: EndCallTrigger.Unload,
          client,
        }),
      ).catch(err => getLogger().fatal({ err }, 'Call cancel failed'))

      throw error
    })
}

export const prolong = (
  client: ApolloClient<any>,
): ThunkAction<Promise<ProlongCallMutation>, AppState, null, Action<string>> => (dispatch, getState) => {
  const call = callSelectors.getSelf(getState())
  const roomId = callSelectors.getRoomId(call)

  return client
    .mutate<ProlongCallMutation, ProlongCallMutationVariables>({
      mutation: PROLONG_MUTATION,
      variables: {
        roomId,
      },
    })
    .then(result => {
      const { data } = result
      const {
        prolongedCall: { room, status },
      } = data

      getLogger().info({ obj: { room, status } }, 'Call prolongation succeed')
      dispatch(onProlong(room.duration, room.end, client))

      return data
    })
    .catch(err => {
      getLogger().error({ err }, 'Call prolongation failed')
      throw err
    })
}

export const onProlong = (
  duration: number,
  roomEnd: string,
  client: ApolloClient<any>,
): ThunkAction<void, AppState, null, Action<string>> => (dispatch, getState) => {
  const call = callSelectors.getSelf(getState())
  const roomId = callSelectors.getRoomId(call)

  analytics.pushEvent('call/prolonged', {
    roomId,
    duration,
  })

  clearEndTimer && clearEndTimer()
  clearEndTimer = waitUntil(new Date(roomEnd).getTime() + seconds(CALL_DURATION_EXTRA_INTERVAL), () => {
    dispatch(end(EndCallTrigger.Timeout, client)).catch(err => getLogger().fatal({ err }, 'Call end failed'))
  })
}

export const end = (
  trigger: EndCallTrigger,
  client: ApolloClient<any>,
): ThunkAction<Promise<void>, AppState, null, Action<string>> => async (dispatch, getState) => {
  const roomId = callSelectors.getRoomId(callSelectors.getSelf(getState()))
  const isConnected = callSelectors.isConnected(callSelectors.getSelf(getState()))
  const isEnding = callSelectors.isEnding(callSelectors.getSelf(getState()))
  let room = null

  clearConnectTimer && clearConnectTimer()
  clearEndTimer && clearEndTimer()

  try {
    const { data } = await getRoomState(roomId, client)
    room = data.room
  } catch (err) {
    getLogger().error({ err }, 'Getting room state failed')
  }

  // Call hasn't started yet
  if (trigger === EndCallTrigger.ButtonEarly && !isConnected) {
    trigger = EndCallTrigger.ButtonFail
    // Call ended using button shortly before end
  } else if (trigger === EndCallTrigger.ButtonEarly && room && differenceInSeconds(new Date(room.end), now()) < 20) {
    trigger = EndCallTrigger.Button
  }

  const variables = {
    roomId,
    trigger,
    meta: { values: { userAgent: navigator && navigator.userAgent } },
  }

  // Prevent calling end multiple times
  if (isEnding || (room && isRoomFinished(room))) {
    getLogger().warn(
      { obj: { roomId, trigger } },
      `Cannot trigger end (with trigger: ${trigger}). Room was already ${room && isRoomCancelled(room) ? 'canceled' : 'ended'
      } (with reason ${room?.reason}).`,
    )
    return
  }

  analytics.pushEvent('call/ended', {
    roomId,
    trigger,
  })

  getLogger().info({ obj: variables }, 'callEnded mutation sent')
  dispatch(actions.endRequest())

  let updatedRoom = null
  try {
    const { data } = await client.mutate<CallEndedMutation, CallEndedMutationVariables>({
      mutation: CALL_ENDED_MUTATION,
      variables,
    })
    updatedRoom = data.room
    dispatch(actions.end(trigger))
    getLogger().info({ obj: variables }, 'callEnded mutation successful')
  } catch (err) {
    // Optimistically end the call (disconnect from Twilio room).
    // We cannot let user stuck with active Twilio connection.
    dispatch(actions.end(trigger))
    getLogger().fatal({ err, obj: variables }, 'callEnded mutation failed')
  }

  if (!updatedRoom?.started || (updatedRoom && isRoomFinished(updatedRoom))) {
    // Intentionally not awaited
    redirectToNextScreen(roomId, client).catch(err => {
      getLogger().error({ err }, 'Redirect to next call screen failed')
    })
  }
}

export const participantConnected = actions.participantConnected

export const participantDisconnected = actions.participantDisconnected

export const setPermissions = actions.setPermissions

export const setSupported = actions.setSupported

export { PROLONG_MUTATION }
