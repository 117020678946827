import gql from 'graphql-tag'

export default gql`
  mutation ProlongCall($roomId: String!) {
    prolongedCall: prolongCall(roomId: $roomId) {
      status
      room {
        id
        duration
        end
      }
    }
  }
`
